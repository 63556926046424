<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card
    no-body
    style="max-height: 400px;"
  >
    <div class="blur-hash-container"
      @click="$emit('view')"
    >
      <car-image-display
        width="360"
        height="170"
        alt="Car image"
        :src="getValueFromSource(booking.car, 'car_images[0].image.path')"
        :hash="getValueFromSource(booking.car, 'car_images[0].image.hash')"
      />
    </div>

    <div class="p-1 parent-div">
      <div>
        <h4 v-b-tooltip.hover class="text-dark ellipsis ellipsis-2 mb-0 pb-0" variant="text" :title="booking.make_title">
          {{ booking.car_model_year }} {{ booking.brand_title }} {{ booking.make_title }}
        </h4>
      </div>
  
      <div>
        <b-badge
          :variant="`light-${resolveStatusVariant(booking.status)}`"
          class="text-capitalize mt-1 mb-1"
        >
          {{ getUserStatusText(booking.status) }}
        </b-badge>
      </div>
  
      <b-card-text class="text-muted ellipsis-4" v-html="booking.description" />
      
      <div class="footer-div">
        <hr>
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="text-dark">
           <small class="text-muted">
            Starting From
           </small>  ${{ formatMoney(getBasePrice(booking)) }}/<small class="text-muted">day</small>
          </h6>
  
          <b-button variant="primary" size="sm" @click="$emit('view')">{{ viewText }}</b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
  import { BCol, BCard, BCardText, BLink, BButton, VBTooltip, BBadge } from 'bootstrap-vue';
  import CarImageDisplay from './CarImageDisplay.vue';

  export default {
    components: {
      BCol,
      BCard, 
      BLink,
      BBadge,
      BButton,
      BCardText,
      CarImageDisplay
    },
    directives: {
      'b-tooltip': VBTooltip
    },
    props: {
      booking: {
        required: true,
        type: Object,
        default: () => ({})
      },
      md: {
        type: Number,
        default: 2
      },
      containerClass: {
        type: String,
        default: 'pl-0'
      }
    },
    computed: {
      viewText() {
        const { booking_payments, status } = this.booking

        if (status !== 'pending') {
          return "View";
        }

        const total_paid = booking_payments.filter(p => p.status === 'success').reduce((acc, el) => {
          return acc + el.amount;
        }, 0);
        if (total_paid < this.booking.total_amount) {
          return "Complete Payment";
        }
        return "View";
      }
    },
    methods: {
      getBasePrice(booking) {
        return this.getValueFromSource(booking, "cost_breakdown.cost_per_day", 0)
      }
    }
  }
</script>

<style lang="scss" scoped>
.rental-card {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.rental-card:hover {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.parent-div {
  height: 90%; 
  display: flex;
  flex-direction: column;
}

.footer-div {
  margin-top: auto;         
}

.blur-hash-container {
  overflow: hidden;   
  border-radius: 8px 8px 0px 0px;
  height: 250px;
}
</style>
