<template>
  <user-pages-wrapper title="My Rentals" :loading="loading">
    <div v-if="rentals.length">
      <b-col cols="12" md="12" class="ml-0">
        <b-row class="match-height">
          <b-col v-for="(rental, i) in rentals" :key="i" md="3">
            <rental-car-display-card :booking="rental" container-class="" @view="viewRental(rental)" />
          </b-col>
        </b-row>
      </b-col>
      
      <b-sidebar
        v-model="showSidebar"
        width="350px"
        bg-variant="white"
        size="md"
        backdrop-variant="dark"
        backdrop
        title="Car Booking"
        no-close-on-backdrop
        no-close-on-esc
        right
        shadow
      >
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between w-100"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="cursor-pointer"
            @click="hideSidebar(true)"
          />
          <h4
            style="font-weight: 700; color: black"
            class="text-black m-0 p-0"
          >
            Car Booking
          </h4>
          <feather-icon
            icon="MoreHorizontalIcon"
            size="18"
            class="cursor-pointer"
          />
        </div>
      </template>
        <b-overlay :show="loading">
          <div v-if="currentBooking" class="p-2">
            <b-alert :show="true" :variant="getAlertVariant(currentBooking)" class="mb-2 text-center" style="padding-top: 0.25rem; padding-bottom: 0.25rem;">
              {{ startCase(getUserStatusText(currentBooking.status)) }}
            </b-alert>
            <div class="d-flex flex-column justify-content-between">
              <MyBookingCarDisplayCard :total-amount="currentBooking.total_amount" :booking="currentBooking" />
            </div>
            <div class="d-flex flex-column mt-3" style="gap: 30px;">
              <div>
                <h1 class="text-18 text-black mb-0.5">Booked On</h1>
                <span>{{ formatDate(currentBooking.created, 'do MMMM yyyy - HH:mm') }}</span>
              </div>
              <div>
                <h1 class="text-18 text-black mb-1">Select Day/Period of Rental</h1>
                <div class="root-pickr">
                  <div class="d-flex flex-column duration from">
                    <label>From</label>
                    <span>{{ formatDate(currentBooking.booking_start_date, 'd MMM, yyyy') }}</span>
                  </div>
                  <div style="height: 30px; background-color: #d9d9d9; width: 2px"></div>
                  <div class="d-flex flex-column duration from">
                    <div class="d-flex justify-content-end">
                      <label>To</label>
                    </div>
                    <span>{{ formatDate(currentBooking.booking_end_date, 'd MMM, yyyy') }}</span>
                  </div>
                </div>
              </div>
              <div>
                <h1 class="text-18 text-black mb-1">Details</h1>
                <div class="d-flex flex-column" style="gap: 10px; border: 1px solid #d9d9d9; padding: 10px; border-radius: 4px;">
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">No. of days</h5>
                    <h5 class="text-muted text-14">{{ computeBookingDays(currentBooking) }}</h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">Pickup Point</h5>
                    <h5 class="text-muted text-14">{{ startCase(getValueFromSource(currentBooking, 'pickup_location')) }}</h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">Airport Drop Off</h5>
                    <h5 class="text-muted text-14">{{ startCase(get(currentBooking, 'airport_drop_off', false) ? 'Yes' : 'No') }}</h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">Pick up</h5>
                    <h5 class="text-muted text-14">{{ get(currentBooking, 'booking_start_time', 'n/a') }}</h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">Return time</h5>
                    <h5 class="text-muted text-14">{{ get(currentBooking, 'booking_end_time', 'n/a') }}</h5>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between">
                  <h1 class="text-18 text-black">Booking Locations</h1>
                  <div v-if="hasFreeDays(currentBooking)" class="d-flex cursor-pointer justify-content-end align-items-center" @click="editBookingLocation = !editBookingLocation">
                    <feather-icon icon="EditIcon" size="15" style="margin-right: 5px;" />
                    Edit
                  </div>
                </div>
                <div v-if="editBookingLocation">

                  <b-form-group label-for="destination" style="max-height: 400px; overflow-y: auto;">
                    <b-form-checkbox-group
                      v-model="editingBooking.destinations"
                      name="car-types"
                      class="w-full demo-inline-spacing"
                    >
                    <small class="text-warning">
                      You have {{ freeDays() }} days available for allocation to any booking location of your choice.
                      These will come at extra cost.
                    </small>
                      <b-row>
                        <b-col v-for="(carPrice, i) in currentBookingPrices(currentBooking)" :key="i" cols="6" md="12" class="w-full mb-1">
                          <div class="w-full d-flex justify-content-between align-items-center">
                            <b-form-checkbox 
                              :value="carPrice._id" 
                              class="text-dark"
                            >
                              <span class="text-dark">
                                {{ carPrice.location.title }} <small class="text-muted">${{ carPrice.amount }}/day</small>
                              </span>
                            </b-form-checkbox>
                            <div v-if="editingBooking.destinations.includes(carPrice._id)" class="d-flex align-items-center">
                              <feather-icon icon="MinusIcon" size="18" class="cursor-pointer operator-btn" @click="updateDaysAtLocation(carPrice._id, -1)" />
                              <span>{{ editingBooking.daysAtLocation[carPrice._id] || 0 }}</span> <small class="text-muted" style="margin-left: 5px;">days</small>
                              <feather-icon icon="PlusIcon" size="18" class="cursor-pointer operator-btn" @click="updateDaysAtLocation(carPrice._id, 1)" />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <small class="text-danger">{{ editedLocationsText }}</small>
                      <b-button :disabled="!hasEditedLocations" class="w-full w-100" variant="success" @click="saveEditedLocations">Save Locations</b-button>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div v-else>
                  <small class="text-warning">This car is available for booking primarily in {{ getCarBookingLocation(currentBooking) }}.</small>
                  <div v-for="(location, index) in currentBookingLocations()" :key="index" class="d-flex justify-content-between" style="gap: 10px;">
                    <span>{{ location.title }}</span>
                    <span>{{ location.days_at_location }}<small class="text-muted ml-1">days</small></span>
                  </div>
                </div>
              </div>

              <div>
                <h6 class="text-18 mb-2">
                  Cost Breakdown
                </h6>
                <div class="d-flex flex-column" style="gap: 7px;">
                  <div class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.cost_per_day')) }}/<span class="text-muted">day</span>
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.cost_per_day') * computeBookingDays(currentBooking)) }}
                    </b-card-text>
                  </div>

                  <div v-if="get(currentBooking, 'cost_breakdown.airport_pickup', 0)" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Airport Pick Up
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.airport_pickup')) }}
                    </b-card-text>
                  </div>

                  <div v-if="locationAddons(currentBooking)" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Extra Location Charges
                      <feather-icon v-b-tooltip.hover v-b-tooltip.hover.html="locationsTooltip" icon="HelpCircleIcon">
                      </feather-icon>
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                    ${{ formatMoney(locationAddons(currentBooking)) }}
                    </b-card-text>
                  </div>

                  <div v-if="get(currentBooking, 'airport_drop_off', false)" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Airport Drop Off
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.airport_drop_off')) }}
                    </b-card-text>
                  </div>

                  <div v-if="get(currentBooking, 'cost_breakdown.driver_accommodation_cost', 0)" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Driver Accommodation Cost
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.driver_accommodation_cost')) }}
                    </b-card-text>
                  </div>

                  <div v-if="get(currentBooking, 'cost_breakdown.after_hours_cost', 0)" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      After Hours Cost
                    </b-card-text>

                    <b-card-text class="p-0 m-0">
                      ${{ formatMoney(get(currentBooking, 'cost_breakdown.after_hours_cost')) }}
                    </b-card-text>
                  </div>

                  <div class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Total Cost
                    </b-card-text>

                    <b-card-text class="p-0 m-0 text-18">
                      ${{ formatMoney(currentBooking.total_amount) }}
                    </b-card-text>
                  </div>

                  <div v-if="remainingAmount(currentBooking) > 0 && currentBooking.status !== 'cancelled'" class="d-flex align-items-center justify-content-between mb-0.5">
                    <b-card-text class="p-0 m-0">
                      Amount to Pay
                    </b-card-text>

                    <b-card-text class="p-0 m-0 text-18">
                      ${{ formatMoney(remainingAmount(currentBooking)) }}
                    </b-card-text>
                  </div>
                  <b-button v-if="remainingAmount(currentBooking) > 0 && currentBooking.status !== 'cancelled'" variant="primary" class="w-100" @click="makeFinalPayment(currentBooking)">
                    Complete Payment
                  </b-button>
                </div>
              </div>

              <div>
                <h6 class="text-18 mb-2">
                  Payments
                </h6>
                <div class="d-flex flex-column" style="gap: 10px; border-radius: 4px;">
                  <div v-for="(payment, index) of getValueFromSource(currentBooking, 'booking_payments', [])" :key="index" class="d-flex justify-content-between">
                    <h5 class="text-dark text-14">{{ formatDate(payment.modified, "dd-MM-yyyy HH:mm") }}</h5>
                    <h5 class="text-14">${{ formatMoney(payment.amount) }}</h5>
                  </div>
                  <div v-if="getValueFromSource(currentBooking, 'booking_payments', []).length === 0">
                    <h5 class="text-dark">No payments processed at the moment</h5>
                  </div>
                </div>
              </div>
            </div>


            <div v-if="canCancel(currentBooking)" class="mt-3">
              <small class="text-muted">{{ cancellationWarning(currentBooking) }}</small>
              <b-button variant="danger" class="w-100" @click="cancelBooking(currentBooking)">
                Cancel Booking
              </b-button>
            </div>
          </div>
        </b-overlay>
      </b-sidebar>
    </div>
    <div v-else>
      <h3 class="text-dark">No cars in your rental history</h3>
    </div>
  </user-pages-wrapper>
</template>

<script>
import CarsMixin from "@core/mixins/cars";

import {
  BRow,
  BCol,
  BImg,
  BLink,
  BOverlay,
  BCardText,
  BJumbotron,
  BCard,
  BAlert,
  BBadge,
  BButton,
  VBTooltip,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSidebar,
} from "bootstrap-vue";
import { differenceInHours } from "date-fns";
import { startCase, get, trimEnd } from "lodash";
import AppSideBar from '@/@core/components/shared/AppSideBar.vue';
import UserPagesWrapper from "@/@core/layouts/components/UserPagesWrapper.vue";
import RentalCarDisplayCard from "@/@core/components/shared/RentalCarDisplayCard.vue";
import MyBookingCarDisplayCard from "@/@core/components/shared/MyBookingCarDisplayCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    MyBookingCarDisplayCard,
    AppSideBar,
    BBadge,
    BAlert,
    BCard,
    UserPagesWrapper,
    RentalCarDisplayCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BSidebar,
    BOverlay,
    BCardText,
    BJumbotron,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [CarsMixin],
  data() {
    return {
      loading: false,
      rentals: [],
      showSidebar: false,
      currentBooking: null,
      editBookingLocation: false,
      editingBooking: {
        destinations: [],
        daysAtLocation: {}
      },
      reopen: ''
    };
  },
  computed: {
    editedLocationsText() {
      const totalExtraDays = Object.values(this.editingBooking.daysAtLocation).reduce((acc, days) => acc + days, 0);
      if (this.editingBooking.destinations.length === 0 || totalExtraDays === 0) {
        return "";
      }

      const car_pricing = this.getValueFromSource(this.currentBooking, 'car.car_pricing', []);
      let additionalCosts = 0;
      Object.keys(this.editingBooking.daysAtLocation).forEach(location => {
        if (this.editingBooking.destinations.includes(location)) {
          const car_price = car_pricing.find(pricing => pricing._id === location);
          if (car_price) {
            additionalCosts += car_price.amount * this.editingBooking.daysAtLocation[location] || 0;
          }
        }
      })
      return `You'll have to pay an additional fee of $${additionalCosts}.`
    },
    hasEditedLocations() {
      return this.editingBooking.destinations.length && Object.values(this.editingBooking.daysAtLocation).some(value => value > 0);
    }
  },
  watch: {
    "editingBooking.destinations": {
      handler(newVal) {
        Object.keys(this.editingBooking.daysAtLocation).forEach(location => {
          if (!newVal.includes(location)) {
            delete this.editingBooking.daysAtLocation[location];
          }
        });
      },
      deep: true
    },
    showSidebar: {
      handler(val) {
        if (!val) {
          this.editingBooking = {
            destinations: [],
            daysAtLocation: {}
          }
          this.editBookingLocation = false
        }
      }
    }
  },
  mounted() {
    this.fetchPageData();
  },
  methods: {
    async fetchPageData() {
      try {
        this.loading = true;
        const response = await this.useJwt().ristic.car_bookings.fetch();

        const data = this.getValueFromSource(response, "data.data");

        this.rentals = data.map(this.makeCarDisplayData);
        if (this.reopen) {
          const booking = this.rentals.find(rental => rental.id === this.reopen);
          if (booking) {
            this.currentBooking = booking;
            this.showSidebar = true;
          }
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async makeFinalPayment(booking) {
      try {
        this.loading = true;

        const result = await this.$swal.fire({
          title: "Confirm Payment?",
          html: `Make payment of <b>$${this.formatMoney(this.remainingAmount(booking))}</b> for this booking?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, pay now.",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }
        
        const response = await this.useJwt().ristic.car_bookings.payRemainder(booking._id);
        const response_data = this.getValueFromSource(response, 'data.data')

        const { url } = response_data;
        location.href = url;
      } catch (error) {
        console.log("ERROR", error);
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async cancelBooking(booking) {
      try {
        this.loading = true;

        const [, penalty, ] = this.computeRefundAndPenalty(booking);
        const penalty_message = penalty ? this.cancellationWarning(booking) : '';

        const result = await this.$swal.fire({
          title: "Confirm Booking Cancellation?",
          text: `Are you sure you want to cancel this booking? ${penalty_message}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, cancel.",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }
        
        await this.useJwt().ristic.car_bookings.cancel(booking._id);
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success!",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Booking cancelled. Your refund will be processed shortly.",
          },
        });
        this.fetchPageData();
        this.hideSidebar();
      } catch (error) {
        console.log("ERROR", error);
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async saveEditedLocations() {
      try {
        this.loading = true;

        if (!this.currentBooking) {
          throw new Error("No booking selected.");
        }

        const result = await this.$swal.fire({
          title: "Confirm Booking Location Change?",
          text: `Are you sure you want to cancel this booking? ${this.editedLocationsText}.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, cancel.",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }
        
        const payload = {
          source_location_ids: this.editingBooking.destinations,
          days_at_location: this.editingBooking.daysAtLocation
        }
        await this.useJwt().ristic.car_bookings.updateLocations(this.currentBooking._id, payload);
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success!",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Booking locations updated. The additional cost of your booking will be updated.",
          },
        });
        if (this.currentBooking) {
          this.reopen = this.currentBooking._id
        }
        this.fetchPageData();
        this.hideSidebar();
      } catch (error) {
        console.log("ERROR", error);
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    updateDaysAtLocation(location, numberOfDays) {
      let locationDays = this.editingBooking.daysAtLocation[location] || 0;
      locationDays += numberOfDays;
      if (locationDays < 0) {
        return;
      }

      const newDaysAtLocation = { ...this.editingBooking.daysAtLocation, [location]: locationDays };
      const total = Object.values(newDaysAtLocation).reduce((accumulation, days) => accumulation + days, 0);
      if (total > this.freeDays()) { return }
      this.editingBooking.daysAtLocation = newDaysAtLocation
    },
    hasExtraLocations(booking) {
      const extra_location_charges = this.getValueFromSource(booking, "cost_breakdown.extra_location_charges", [])
      return extra_location_charges && extra_location_charges.length > 0;
    },
    locationAddons(booking) {
      let total = 0;
      const extra_location_charges = this.getValueFromSource(booking, "cost_breakdown.extra_location_charges", [])
      extra_location_charges.forEach((location) => {
        total += location.cost_per_day * location.days_at_location;
      });
      return total
    },
    locationsTooltip() {
      let finalHtml = ''
      const extra_location_charges = this.getValueFromSource(this.currentBooking, "cost_breakdown.extra_location_charges", [])
      extra_location_charges.forEach((p) => {
        finalHtml += `<p class='m-0 p-0'><strong>${p.title}</strong> - $${this.formatMoney(p.cost_per_day)} (${p.days_at_location} days)</p>`
      })
      return finalHtml
    },
    computeRefundAndPenalty(booking) {
      const start_date = new Date(booking.booking_start_date);
      const hours_until_pickup = differenceInHours(start_date, new Date());

      const settings = this.$store.getters[`auth/settings`];
      const full_refund_window = this.getValueFromSource(settings, "car_rental_settings.full_refund_window", -1)
        
      const refund_penalty_percentage = this.getValueFromSource(settings, "car_rental_settings.refund_penalty_percentage", 0)
      const amount_paid = this.computeTotalPayments(booking);

      const qualifiesForFullRefund = hours_until_pickup <= full_refund_window

      if (!refund_penalty_percentage || !this.enableRefunds || qualifiesForFullRefund) {
        return [amount_paid, 0, 0];
      }
        
      // there is a percentage penalty
      const penalty_amount = (amount_paid * refund_penalty_percentage) / 100;
      const refund_amount = amount_paid - penalty_amount;
      return [refund_amount, penalty_amount, refund_penalty_percentage];
    },
    makeCarDisplayData(rental) {
      const car_pricing = this.getValueFromSource(rental, "car.car_pricing", [])
      const default_location = car_pricing.find(el => el.default_location)
      return {
        title: this.getValueFromSource(rental, "car.car_name"),
        description: this.getValueFromSource(rental, "car.car_description"),
        image: this.getValueFromSource(rental, "car.car_images[0].image"),
        brand_title: this.getValueFromSource(rental, "car.car_brand.title"),
        make_title: this.getValueFromSource(rental, "car.car_make.title"),
        car_model_year: this.getValueFromSource(rental, "car.car_model_year"),
        rate: default_location ? default_location.amount : 0,
        ...rental,
      };
    },
    viewRental(rental) {
      this.currentBooking = rental;
      this.showSidebar = true;
    },
    hideSidebar(clearReopen = false) {
      this.showSidebar = false;
      if (clearReopen) {
        this.reopen = null
      }
    },
    startCase,
    get,
    trimEnd,
    computeAdditionalLocationBasedCosts(booking) {
      return this.getValueFromSource(booking, "car_pricing").reduce((acc, el) => {
        return acc + el.amount;
      }, 0);
    },
    cancellationWarning(booking) {
      const [refund_amount, penalty, percentage] = this.computeRefundAndPenalty(booking);
      const penalty_message = penalty ? `You'll be charged a penalty of $${penalty} (${percentage}%) for cancelling this booking. You'll be refunded $${this.formatMoney(refund_amount)}.` : '';
      return penalty_message
    },
    canCancel(booking) {
      if (!booking) return false;
      const canRefundBasedOnStatus = !['cancelled', 'completed'].includes(booking.status)
      return canRefundBasedOnStatus && this.enableRefunds
    },
    getCarBookingLocation(booking) {
      return this.getValueFromSource(booking, "booking_location.title", '')
    },
    hasFreeDays() {
      if (!this.currentBooking) return false;
      const extra_location_charges = this.getValueFromSource(this.currentBooking, "cost_breakdown.extra_location_charges", [])

      const totalDays = extra_location_charges.reduce((acc, curr) => acc + curr.days_at_location, 0);
      return this.computeBookingDays(this.currentBooking) > totalDays
    },
    freeDays() {
      if (!this.currentBooking) return false;
      const extra_location_charges = this.getValueFromSource(this.currentBooking, "cost_breakdown.extra_location_charges", [])

      const totalDays = extra_location_charges.reduce((acc, curr) => acc + curr.days_at_location, 0);
      return this.computeBookingDays(this.currentBooking) - totalDays
    },
    currentBookingLocations() {
      if (!this.currentBooking) return false;
      const extra_location_charges = this.getValueFromSource(this.currentBooking, "cost_breakdown.extra_location_charges", [])
      // const totalDays = extra_location_charges.reduce((acc, curr) => acc + curr.days_at_location, 0);
      return extra_location_charges
    },
    currentBookingPrices(booking) {
      console.log("booking", booking)
      const prices = this.getValueFromSource(booking, "car.car_pricing", [])
      console.log("prices", prices)
      return prices.filter(p => !this.getValueFromSource(p, "location.is_default_location", false))
    }
  }
};
</script>

<style>
.root-pickr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 5px;
}
</style>
